/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { ContentRecommendation } from '@/components/content/ContentRecommendation';
import { ResponseProductAttribute } from '@/data/types/Product';
import { Box, Stack } from '@mui/material';
import { FC, useMemo } from 'react';

export const FinalSale: FC<{
	isMiniCart?: boolean;
	attributes: ResponseProductAttribute[];
	partNumber: string;
}> = ({ isMiniCart = false, attributes, partNumber }) => {
	const isFinalSale = useMemo(() => {
		let isFinalSale = false;
		attributes
			.filter((attr) => attr.usage === 'Descriptive' && attr.name === 'Closeout')
			.forEach(({ values }) =>
				values.forEach((value) => {
					if (value.value === 'F') {
						isFinalSale = true;
					}
				})
			);
		return isFinalSale;
	}, [attributes]);

	return isFinalSale ? (
		<Box sx={{ marginTop: '12px' }}>
			<Stack>
				{isMiniCart ? (
					<ContentRecommendation
						key="ReactMiniCartFinalSaleMessage"
						id={`minicart-ReactMiniCartFinalSaleMessage-${partNumber}`}
						properties={{ emsName: 'ReactMiniCartFinalSaleMessage' }}
					/>
				) : (
					<ContentRecommendation
						key="ReactFinalSaleMessage"
						id={`cart-ReactFinalSaleMessage-${partNumber}`}
						properties={{ emsName: 'ReactFinalSaleMessage' }}
					/>
				)}
			</Stack>
		</Box>
	) : null;
};
